import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from './Component/Layout/Header';
import Title from './Component/Content/Title';
import SectionOne from './Component/Content/SectionOne';
import SectionTwo from './Component/Content/SectionTwo';
import SectionThree from './Component/Content/SectionThree';
import SectionFour from './Component/Content/SectionFour';
import About from './Component/Content/About';
import HowItWorks from './Component/Content/HowItWorks';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router>
      <Switch>
        <Container className="parent-container" fluid>
          {/* <Splash /> */}
          <Header />
          <Route exact path="/about" component={About} />
          <Route exact path="/" component={Title} />
          <Route exact path="/" component={SectionOne} />
          <Route exact path="/" component={SectionTwo} />
          <Route exact path="/" component={SectionThree} />
          <Route exact path="/" component={SectionFour} />
          <Route exact path="/" component={HowItWorks} />
        </Container>
      </Switch>
    </Router>
  );
}

export default App;
