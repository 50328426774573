import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './SectionThree.css';

function SectionThree() {
  return (
    <>
      <Container className="section-three-container" fluid>
        <Col>
          <Row className="section-three-title-text">
            When we can help
          </Row>
          <Row className="section-three-text">
            We can help if your property has been affected
            by water, storms, impact, fire and other damaging factors
          </Row>
          <Row className="section-three-title-text">
            Our Assessors
          </Row>
          <Row className="section-three-text">
            Our loss assessors have over 30 years of experience working in building design,
            construction and management throughout the United Kingdom and the Republic of Ireland
          </Row>
        </Col>
      </Container>
    </>
  );
}

export default SectionThree;
