import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import InstagramIcon from '@material-ui/icons/Instagram';
// import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import IconButton from '@material-ui/core/IconButton';
import logo from '../Images/loki-logo-footer.png';
import './HowItWorks.css';

function HowItWorks() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="how-it-works-container" fluid>
      <Row>
        <Col className="how-it-works-col">
          <Row className="how-it-works-title-text">
            Our Process
          </Row>
          <Row className="how-it-works-text">
            <ol>
              <li>
                We will survey your damaged property&nbsp;
                <u>within 24 hours of notification</u>
              </li>
              <br />
              <li>
                We will compile a detailed report of required
                repairs with costs and forward this to your insurers
              </li>
              <br />
              <li>
                We will meet with the appointed loss adjuster and will
                negotiate compensation / repairs with your insurers
              </li>
              <br />
              <li>
                Once a settlement has been reached, our fee is paid from the settlement
              </li>
              <br />
              <li>
                We also offer an additional service where we oversee the
                repair works to ensure that your property is restored to its rightful condition
              </li>
              <br />
              <li>
                You are always in control as the settlement cheque goes directly to you
              </li>
            </ol>
          </Row>
          <Row className="image-row">
            <img className="logo-image" alt="loki-logo" src={logo} />
          </Row>
          <Row className="image-row">
            {/* <IconButton target="_blank" href="https://www.instagram.com/loki_insurance_assessors/?igshid=2zmgfdin5js9">
              <FacebookIcon style={{ fontSize: '50px' }} className="social-link" />
            </IconButton> */}
            <IconButton target="_blank" href="https://www.instagram.com/loki_insurance_assessors/?igshid=2zmgfdin5js9">
              <InstagramIcon style={{ fontSize: '50px' }} className="social-link" />
            </IconButton>
            <IconButton target="_blank" href="https://twitter.com/LokiAssessors">
              <TwitterIcon style={{ fontSize: '50px' }} className="social-link" />
            </IconButton>
          </Row>
          <Row className="image-row">
            <Link style={{ paddingBottom: '16px' }} className="about-link" to="/about">About us</Link>
          </Row>
          <Row className="image-row">
            <a
              className="how-it-works-text"
              style={{ padding: '0' }}
              href="tel:+447859999446"
            >
              T: +44 (0) 7859999446
            </a>
          </Row>
          <Row className="image-row">
            <a
              className="how-it-works-text"
              style={{ paddingTop: '0' }}
              href="mailto:loki.assessors@gmail.com?subject=Email Enquiry From LoKiAssessors.com"
            >
              E: loki.assessors@gmail.com
            </a>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default HowItWorks;
