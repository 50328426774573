import React, { useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import stephen from '../Images/steven-profile-pic.jpg';
import './About.css';

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="about-container" fluid>
      <Row className="about-main-row">
        <Col className="flex-columns" />
        <Col style={{ flex: '6' }}>
          <Row className="about-title-text">
            About Us
          </Row>
          <Row className="about-text">
            LoKi Insurance Assessors was founded in 2021, to provide a complete service
            regarding the subject of insurance loss assessing.
            <br />
            Founding member Stephen Sheridan is collaborating with highly experienced
            loss assessors who have gained over 30 years’
            experience in building design, construction, and management throughout ROI and the UK.
            <br />
            <br />
            Our team is dedicated to provide a hands-on service to people who have suffered
            loss or damage accidentally in their home or business.
            <br />
            Through strong leadership, negotiation and communication,
            we will work on behalf of the client to ensure you get your
            full entitlement under the terms and conditions of your policy.
            <br />
            <br />
            In the event of a claim, we will work on behalf of you, the policy holder,
            and deal with all aspects of your claim from initial
            reporting to the careful preparation
            and negotiation of your claim.
            <br />
            Our goal is to ensure a strong working relationship with our clients
            and bring them to a fair and justifiable settlement with their insurance provider
            <img
              style={{ width: '100%', marginTop: '5%', borderRadius: '2%' }}
              src={stephen}
              alt="stephen"
            />
          </Row>
          <Row className="about-title-text">
            Stephen Sheridan
          </Row>
          <Row style={{ paddingBottom: '5%' }} className="stephen-paragraph">
            Stephen studied in the University of Ulster where he obtained a
            Master’s Degree in Business Development and Innovation.
            <br />
            His knowledge continued to grow, working in the Software Industry where he
            gained understanding and experience in all aspects of Health and
            Safety across all sectors.
          </Row>
        </Col>
        <Col className="flex-columns" />
      </Row>
    </Container>
  );
}

export default About;
