import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTint, faHammer, faFire, faBolt,
} from '@fortawesome/free-solid-svg-icons';
import './SectionFour.css';

function SectionFour() {
  return (
    <>
      <Container className="section-four-container" fluid>
        <Row>
          <Col className="section-four-col">
            <Row className="section-four-row">
              <FontAwesomeIcon icon={faFire} size="7x" style={{ color: '#319BE9' }} />
            </Row>
            <Row className="section-four-title-text">
              Fire Damage
            </Row>
          </Col>
          <Col className="section-four-col">
            <Row className="section-four-row">
              <FontAwesomeIcon icon={faTint} size="7x" style={{ color: '#319BE9' }} />
            </Row>
            <Row className="section-four-title-text">
              Flood Damage
            </Row>
          </Col>
          <Col className="section-four-col">
            <Row className="section-four-row">
              <FontAwesomeIcon icon={faHammer} size="7x" style={{ color: '#319BE9' }} />
            </Row>
            <Row className="section-four-title-text">
              Impact Damage
            </Row>
          </Col>
          <Col className="section-four-col">
            <Row className="section-four-row">
              <FontAwesomeIcon icon={faBolt} size="7x" style={{ color: '#319BE9' }} />
            </Row>
            <Row className="section-four-title-text">
              Storm Damage
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SectionFour;
