import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Title.css';

function Title() {
  return (
    <Container className="title-container" fluid>
      <Row className="title-row">
        <Col style={{ padding: '0' }}>
          <div className="title-text">LoKi Insurance Assessors</div>
          <div className="title-sub-text">Talk to us, we&apos;re all ears!</div>
        </Col>
      </Row>
    </Container>
  );
}

export default Title;
