import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './SectionOne.css';

function SectionOne() {
  return (
    <>
      <Container className="section-one-container" fluid>
        <Col className="section-one-main-col">
          <Row className="section-one-title-text">
            LoKi Assessors works for you
          </Row>
          <Row className="section-one-text">
            When appointed, Loki Assessors will act on behalf of you, the client,
            to assess and negotiate the best settlement of any property and/or
            business interruption claim.
            <br />
            <br />
            Working solely on your behalf as professional assessors, we will review your policy
            and ensure you receive your full entitlement from your insurance company.
            <br />
            <br />
            We aim to take your claim through the correct process,
            getting you the compensation you deserve.
            <br />
          </Row>
        </Col>
      </Container>
    </>
  );
}

export default SectionOne;
