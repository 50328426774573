import React from 'react';
import { useHistory } from 'react-router-dom';
import './layout.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import HomeIcon from '@material-ui/icons/Home';
import EmailIcon from '@material-ui/icons/Email';

function Header() {
  const history = useHistory();

  const value = false;

  function moveUp() {
    if (window.location.pathname !== '/') {
      history.push('/');
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }

  return (
    <>
      <header className="App-header header sticky">
        <Tabs
          variant="fullWidth"
          value={value}
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon tabs example"
        >
          <Tab icon={<PhoneIcon />} aria-label="phone" href="tel:+447859999446" alt="phone" />
          <Tab icon={<HomeIcon />} aria-label="info" alt="info" onClick={moveUp} />
          <Tab
            icon={<EmailIcon />}
            aria-label="email"
            alt="email"
            href="mailto:loki.assessors@gmail.com?subject=Email Enquiry From LoKiAssessors.com"
          />
        </Tabs>
      </header>
    </>
  );
}

export default Header;
