import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import WorkIcon from '@material-ui/icons/Work';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import './SectionTwo.css';

function SectionTwo() {
  return (
    <>
      <Container className="section-two-container" fluid>
        <Row>
          <Col className="section-two-col">
            <Row className="section-two-row">
              <WorkIcon style={{ fontSize: '64' }} />
            </Row>
            <Row className="section-two-title-text">
              Claim Management
            </Row>
            <Row className="section-two-text">
              We will take your claim from start to finish,
              ensuring your best interests are at heart
            </Row>
          </Col>
          <Col className="section-two-col" style={{ paddingLeft: '16', paddingRight: '16' }}>
            <Row className="section-two-row">
              <AccountBalanceIcon style={{ fontSize: '64' }} />
            </Row>
            <Row className="section-two-title-text">
              What you deserve
            </Row>
            <Row className="section-two-text">
              We will push to get you the cash settlement you deserve
            </Row>
          </Col>
          <Col className="section-two-col">
            <Row className="section-two-row">
              <AccessibilityNewIcon style={{ fontSize: '64' }} />
            </Row>
            <Row className="section-two-title-text">
              Hands on approach
            </Row>
            <Row className="section-two-text">
              We believe every claim is personal and we will
              take a hands-on approach from start to settlement,
              ensuring the best service for our clients
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SectionTwo;
